// Route guard middleware - used on signup pages
export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore();
  const {
    showRegisterForm,
    showVerificationCodeForm,
    showAvatarForm,
    isUserLogged,
  } = storeToRefs(authStore);
  if (isUserLogged.value) {
    return navigateTo({
      path: '/',
    });
  }
  if (!showRegisterForm.value && !showVerificationCodeForm.value && !showAvatarForm.value) {
    window.location.href = '/';
  }
});
